import React from "react";
// import { Link } 				from "gatsby"
import styled from "styled-components";

import Seo from "../components/seo";
import BackgroundImage from "../images/home/vastech-bg.webp";
import { SubHeader, Body, Header } from "../components/Typography";
import { SiteContent, } from "../components/Layouts";
import { Breakpoints, Colors } from "../theme";
import IndustryHero from "../components/Heros/IndustryHero";
import { HomePageData } from "../data/home-page";
import { LargeCard } from "../components/Cards";
import { AboutData } from "../data/about";

// Images
import OrganicCircles from "../images/svgs/thumbsupstudio_organicCircles.svg";

import "../theme/css/animations.css";
import { NumberCard } from "../components/Cards";

const AboutImageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  min-height: 1200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${Colors.Primary};
  background: linear-gradient(-180deg, ${Colors.Primary} 0%, ${Colors.SuperDarkPurple} 100%);
  /* background-image: url(${BackgroundImage}); */

  @media only screen and (max-width: 780px) {
    padding-top: 54px;
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  min-height: 600px;

  @media only screen and (max-width: 780px) {
    min-height: 800px;
    padding-top: 100px;
  }
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 120px;
`;


const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* gap: 64px; */
  text-align: center;
  max-width: 866px;
  margin-bottom: 120px;
`;

const VertDivider = styled.div`
  width: 1px;
  background-color: ${Colors.BabyBlue};
  margin: 16px 0;
  /* height: 100%; */
  height: 64px;

  @media only screen and (max-width: ${Breakpoints.mobile}) {
  height: 1px;
  width: 30%;
  }
`;


const LargeCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 64px;
`;

const AreaSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 32px;
  text-align: center;
  max-width: 866px;
  margin-bottom: 120px;
`;

const QuoteSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 32px;
  text-align: center;
  max-width: 866px;
  margin-bottom: 120px;
`;

const AreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  border: 1px solid ${Colors.Pink};
  padding: 8px 16px;
  border-radius: 8px;
  @media only screen and (max-width: ${Breakpoints.tabletSmall}) {
    flex-direction: column;
  }
`;

const AreaItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  @media only screen and (max-width: ${Breakpoints.tabletSmall}) {
    flex-direction: column;
  }
`;

const AboutItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const AboutPage = () => {
  let { hero, numberSection, aboutSection, areasServedSection } = AboutData;
  return (
    <AboutImageWrapper>
      <Seo title="VASTech Solutions | About Us" />
      <HeroWrapper>
        <IndustryHero inverted residential data={hero} />
      </HeroWrapper>
      <SiteContent center>
        <NumberWrapper>
          {numberSection.map((data, index) => {
            return (
              <NumberCard
                index={index}
                key={'indunumberSectionystryCard' + index}
                data={data}
              />
            );
          })}
        </NumberWrapper>
        <AboutSection>
          {aboutSection.map((data, index) => {
            return (
              <AboutItem
                data-aos="fadeInUp"
                data-aos-duration={350}
                data-aos-delay={index * 200}>
                <Header h4>{data.title}</Header>
                <Body color={Colors.White}>{data.body}</Body>
                {index !== aboutSection.length - 1 && <VertDivider></VertDivider>}
              </AboutItem>
            );
          })}
        </AboutSection>
        <LargeCardWrapper>
          {HomePageData.indsustries.map((data, index) => {
            return (
              <LargeCard
                key={'induystryCard' + index}
                data={data}
                index={index}
              />
            );
          })}
        </LargeCardWrapper>
        <QuoteSection>
          <Header h3 color={Colors.White}>“Doing the right thing for the customer to succeed is the backbone of my business. I’m not just a technology vendor—I  build relationships with each client to learn what they want or need. Then I stay in touch to make sure it’s fully functional and easy to use.”</Header>
          <VertDivider></VertDivider>
          <div>
            <SubHeader color={Colors.Commercial}>Vic Scaccia</SubHeader>
            <Body color={Colors.White}>Owner & Operator</Body>
          </div>
        </QuoteSection>
        <AreaSection>
          <Header h4 color={Colors.White}>Proud to serve customers in:</Header>
          <AreaWrapper>
            {areasServedSection.map((data, index) => {
              console.log('length', areasServedSection.length)
              return (
                <AreaItem>
                  <Body opacity color={Colors.White}>{data.body}</Body>
                  {index !== areasServedSection.length - 1 && <Body color={Colors.Pink}>| </Body>}
                </AreaItem>
              );
            })}
          </AreaWrapper>
          <Body color={Colors.White}>Not in our area? No problem. <br /> We’ll hit the road and hook you up with the best AV setup anywhere in the continental United States.</Body>
        </AreaSection>
      </SiteContent>
    </AboutImageWrapper>
  )
};

export default AboutPage;
